import React from "react";

function Seperator() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center mb-2">
      <h1 className="heading md:mb-5">Welcome to the Bioblinds hub!</h1>
        </div>
      </div>
    </div>
  );
}

export default Seperator;

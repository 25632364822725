import React from "react";
import {Link} from "gatsby";

const HubTile = () => {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl mx-auto px-1.5 md:px-10 pt-20 ">
        <div className=" ">
          <div className="bg-white md:w-2/4  w-11/12 m-auto px-4 md:px-12 py-20 pb-25">
            <p className="bg-white text-40 text-28 font-bold mb-14">
              Thanks so much for subscribing! 
              <div className="md:text-2xl text-xl font-bold mb-7 mt-3">
              <ul>
              <li>- Please <Link className="underline hover:text-green-default" to="https://bit.ly/bioblinds-1st-edition">click here</Link> to download 'BIOBLINDS 1ST EDITION IVY'<br /><br /> </li>
              <li>- <Link to="https://forms.gle/39MFQHsoZpfdEv8H6" className="underline hover:text-green-default" target="__blank">Click here</Link> to contribute to our research<br /><br /></li>
              <li>- Or <Link to="/get-in-touch" className="underline hover:text-green-default">contact us</Link> if you have any questions, ideas or if you want to collaborate with us<br /><br /> </li>
              </ul>
              </div>
            </p>
            <p className="md:text-2xl text-xl font-bold mb-7">
              Let’s jungle the cities together! 💚 
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubTile;
